import React from "react";
import { BurgerMenu } from "../components/Header/burgerMenu";
import Layout from "../components/layout";
import SEO from "../components/seo";
import GoBack from "../components/Utils/backButton";
import GoHome from "../components/Utils/homeButton";
import * as styles from "../styles/terms.module.scss";

const NotFoundPage: React.FC = () => {
  const handleClick = () => {
    const element = document.getElementById("navvMenu");
    if (element) element.classList.toggle("inactive");
  };

  return (
    <Layout theme="light" withoutLogo={true}>
      <SEO title="404: Not found" />
      <div className={`margin ${styles.pageWrapper}`}>
        <div className={styles.mobileTitle}>
          <GoHome />
          <div className={styles.pageTitle}>{"404: not found"}</div>
          <div id="burgerMenu" className={styles.burgerPosition}>
            <BurgerMenu theme={"light"} onClick={handleClick} />
          </div>
        </div>
        <GoBack />
        <div className={styles.pageTitle}>{"404: not found"}</div>
        {/* <div className={styles.termsContent}> */}
        {/* <div className={styles.articleTitle}>{article.title[lang]}</div> */}
        {/* <div className={styles.articleContent}>This page doesn't exist..</div> */}
        {/* </div> */}
      </div>
    </Layout>
  );
};

export default NotFoundPage;
